<template>
  <div>
    <div></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
@include b(header) {
}
@include b(body) {
}
@include b(footer) {
}
</style>
